import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Spinner from './Spinner';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const DefaultButton = (props) => {
  const {
    className,
    disabled,
    hasFullWidth,
    hasSpinner,
    leftLabel,
    onClick,
    rightLabel,
    spinnerColor,
  } = props;

  const _renderLabel = (label) => {
    if (React.isValidElement(label)) {
      return label;
    }
    return <span>{label}</span>;
  };

  return (
    <button
      className={classNames('btn-default', className, {
        'full-width': hasFullWidth,
        disabled: disabled,
      })}
      disabled={disabled}
      onClick={onClick ? onClick : null}
    >
      <div className="btn-default-content">
        <div className="left-label">{_renderLabel(leftLabel)}</div>
        {rightLabel && (
          <div className="btn-right-label-container">{_renderLabel(rightLabel)}</div>
        )}
        {hasSpinner && (
          <div className="btn-right-label-container mr-20 button-circular-progress-container">
            <Spinner className={className} color={spinnerColor} size="20px" />
          </div>
        )}
      </div>
    </button>
  );
};

DefaultButton.defaultProps = {
  hasSpinner: false,
  hasFullWidth: false,
  disabled: false,
};

DefaultButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasSpinner: PropTypes.bool,
  hasFullWidth: PropTypes.bool,
  leftLabel: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  rightLabel: PropTypes.node,
  spinnerColor: PropTypes.string,
};

/****************************************************************************************

                                        Primary Button

*****************************************************************************************/

export const PrimaryButton = (props) => {
  return (
    <DefaultButton {...props} className={classNames('primary-new', props.className)} />
  );
};

PrimaryButton.propTypes = {
  className: PropTypes.string,
};

/****************************************************************************************

                                        Secondary Button

*****************************************************************************************/

export const SecondaryButton = (props) => {
  return (
    <DefaultButton {...props} className={classNames('secondary-new', props.className)} />
  );
};

SecondaryButton.propTypes = {
  className: PropTypes.string,
};

/****************************************************************************************

                                          Text Button

*****************************************************************************************/

export const TextButton = (props) => {
  return (
    <DefaultButton
      {...props}
      className={classNames('primary-text-new', props.className)}
    />
  );
};

TextButton.propTypes = {
  className: PropTypes.string,
};

/****************************************************************************************

                                    PayPal Express Button

*****************************************************************************************/

export const PayPalExpressButton = (props) => {
  return (
    <DefaultButton {...props} className={classNames('paypal-express', props.className)} />
  );
};

PayPalExpressButton.propTypes = {
  className: PropTypes.string,
};

/****************************************************************************************

                                    Icon button

*****************************************************************************************/

export const IconRoundedButton = (props) => {
  return (
    <button
      {...props}
      className={classNames(
        'rounded-icon-button',
        props.className,
        props.size,
        props.color,
        {
          disabled: props.disabled,
          'no-hover-effects': props.noHoverEffects,
        }
      )}
      disabled={props.disabled}
      onClick={props.onClick ? props.onClick : null}
    >
      {props.icon}
    </button>
  );
};

IconRoundedButton.defaultProps = {
  size: 'medium',
  color: 'blue',
  noHoverEffects: false,
};

IconRoundedButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  color: PropTypes.oneOf(['blue', 'black', 'grey']),
  noHoverEffects: PropTypes.bool,
};

/****************************************************************************************

                                        Disabled to active
disabled by default, after 3 seconds is active 

*****************************************************************************************/

export const DisabledToActiveButton = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
      setAnimationStarted(true);
      const timer = setTimeout(() => {
        setIsDisabled(false);
      }, 3000);
      return () => clearTimeout(timer);
  }, []);

  return (
    <button
      {...props}
      className={classNames('btn-disabed-to-active', props.className, {
        disabled: isDisabled,
        animate: animationStarted,
      })}
      disabled={isDisabled}
    >
      {props.children}
    </button>
  );
};

DisabledToActiveButton.propTypes = {
  className: PropTypes.string,
  buttonIsRendered: PropTypes.bool,
};
