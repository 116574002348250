/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EventInfoCard_eventInfo$ref = any;
type ExtendedSearch_event$ref = any;
type PresaleEventInfo_eventPresaleInfo$ref = any;
type StartNumberSearch_info$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventOnlineView_onlineEvent$ref: FragmentReference;
declare export opaque type EventOnlineView_onlineEvent$fragmentType: EventOnlineView_onlineEvent$ref;
export type EventOnlineView_onlineEvent = {|
  +id: string,
  +sgId: ?number,
  +hostResultUrl: ?string,
  +name: ?string,
  +participantUser: ?boolean,
  +mainSearch: ?string,
  +noMainSearchText: ?string,
  +dialogBeforeSearch: ?string,
  +dialogBeforeSecondFactor: ?string,
  +presale: ?string,
  +$fragmentRefs: PresaleEventInfo_eventPresaleInfo$ref & EventInfoCard_eventInfo$ref & StartNumberSearch_info$ref & ExtendedSearch_event$ref,
  +$refType: EventOnlineView_onlineEvent$ref,
|};
export type EventOnlineView_onlineEvent$data = EventOnlineView_onlineEvent;
export type EventOnlineView_onlineEvent$key = {
  +$data?: EventOnlineView_onlineEvent$data,
  +$fragmentRefs: EventOnlineView_onlineEvent$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "EventOnlineView_onlineEvent",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hostResultUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "participantUser",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mainSearch",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "noMainSearchText",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dialogBeforeSearch",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dialogBeforeSecondFactor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "presale",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PresaleEventInfo_eventPresaleInfo",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCard_eventInfo",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "StartNumberSearch_info",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExtendedSearch_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c47be60c602313ff99aa24a717292418';

module.exports = node;
