/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartLineItemGiftcard_lineItem$ref: FragmentReference;
declare export opaque type CartLineItemGiftcard_lineItem$fragmentType: CartLineItemGiftcard_lineItem$ref;
export type CartLineItemGiftcard_lineItem = {|
  +product: ?{|
    +price: ?any,
    +label: ?string,
    +event: ?{|
      +name: ?string,
      +banner: ?string,
    |},
  |},
  +$refType: CartLineItemGiftcard_lineItem$ref,
|};
export type CartLineItemGiftcard_lineItem$data = CartLineItemGiftcard_lineItem;
export type CartLineItemGiftcard_lineItem$key = {
  +$data?: CartLineItemGiftcard_lineItem$data,
  +$fragmentRefs: CartLineItemGiftcard_lineItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CartLineItemGiftcard_lineItem",
  "type": "LineItem",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "product",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "price",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "label",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "event",
          "storageKey": null,
          "args": null,
          "concreteType": "Event",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "banner",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bf149fcd95d75d58c54b14b558965bcc';

module.exports = node;
