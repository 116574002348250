import React from 'react';
import { FormattedMessageWrappedInSpan } from '../../components/misc';
import { getAppLanguage } from '../../intl/languages';

export default function ContactPage() {
  const linkToContact = (
    <strong>
      <a
        href={`https://helpdesk.sportograf.com/${getAppLanguage()}/support/tickets/new`}
        className="link-blue"
      >
        <FormattedMessageWrappedInSpan
          id="contactPage.support.link"
          defaultMessage="use our contact form"
        />
      </a>
    </strong>
  );

  return (
    <div className="container-960 container-page">
      <h2>
        <FormattedMessageWrappedInSpan id="contactPage.title" defaultMessage="Contact" />
      </h2>
      <br />
      <h1>
        <FormattedMessageWrappedInSpan
          id="contactPage.subtitle"
          defaultMessage="HOW TO CONTACT US"
        />
      </h1>
      <br />
      <h3>
        <span>Sportograf Digital Solutions GmbH</span>
        <br />
        <span>Süsterfeldstr 170</span>
        <br />
        <span>52072 Aachen</span>
        <br />
        <FormattedMessageWrappedInSpan
          id="contactPage.info.country"
          defaultMessage="Germany"
        />
      </h3>
      <p>
        <FormattedMessageWrappedInSpan
          id="contactPage.support.text"
          defaultMessage="To contact our support, please {link}."
          values={{ link: linkToContact }}
        />
      </p>
    </div>
  );
}
