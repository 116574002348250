import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation AddGiftcardToCartMutation($input: AddGiftcardToCartInput!) {
    addGiftcardToCart(input: $input) {
      cart {
        id
        lineItems {
          id
          product {
            id
            price
            type
            label
          }
        }
        totalPrice
      }
    }
  }
`;

function commit(environment, productId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        productId,
      },
    },
    updater: store => {
      const payload = store.getRootField('addGiftcardToCart');
      const cart = payload.getLinkedRecord('cart');
      const viewer = store.get('root');
      viewer.setLinkedRecord(cart, 'cart');
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.addGiftcardToCart.cart);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
