import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import FormattedPrice from '../../misc/FormattedPrice';
import { DvdMsg, FotoFlatMsg, SinglePhotoMsg } from '../../messages';
import classNames from 'classnames';

import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import CircularProgress from '@material-ui/core/CircularProgress';

function CartItem(props) {
  const { isDigital } = props;
  return (
    <>
      <div
        className={`row cart-overview-item ${
          props.title.type === FotoFlatMsg && 'cart-foto-flat-item'
        }`}
      >
        {props.icon}
        <div className="col-xs-11 col-sm-10 cart-overview-item-details ">
          <div className="cart-overview-item-details_title">
            <span
              className={`${props.hasHelpIcon && 'cart-button-element'}`}
              onClick={() => {
                if (props.onHelpClick) {
                  props.onHelpClick();
                }
              }}
            >
              <div
                className={classNames(`cart-typography_product-name`, {
                  'cart-text-with-icon': props.hasHelpIcon,
                })}
              >
                {props.title}
                {props.hasHelpIcon && <HelpRoundedIcon className="icon-button grey" />}
              </div>
            </span>
          </div>
          <div className="cart-overview-item-details_price">
            <span
              className={`cart-item-price cart-typography_product-price ${
                props.discount ? 'cart-item-price_discount' : ''
              }`}
            >
              <p>
                <FormattedPrice price={props.finalPrice} />
              </p>
              {props.discount && (
                <p>
                  <FormattedPrice price={props.originalPrice} />
                </p>
              )}
            </span>
            {props.vat >= 0 && (
              <span className="cart-typography_product-shipping cart-vat-info">
                <FormattedMessageWrappedInSpan
                  id="cartItem.vat"
                  defaultMessage="VAT: {vat}%"
                  values={{ vat: props.vat }}
                />
              </span>
            )}
          </div>
          <div className="cart-overview-item-details_description">
            <span
              className={`${
                props.subtitleHasHelpIcon ? 'cart-button-element' : ''
              } cart-typography_description-big`}
              onClick={() => {
                if (props.onSubtitleHelpClick) {
                  props.onSubtitleHelpClick();
                }
              }}
            >
              {props.subtitle}
              {props.subtitleHasHelpIcon && props.onSubtitleHelpClick && (
                <HelpRoundedIcon className="icon-button grey" />
              )}
            </span>
            <span className="cart-typography_description-small">
              {props.eventNames?.join(', ')}
            </span>
          </div>
          {isDigital && (
            <div
              className="cart-text-with-icon cart-product-shipping-info cart-typography_product-shipping"
              onClick={() => {
                if (props.onToggleDvdDialog) {
                  props.onToggleDvdDialog();
                }
              }}
            >
              <GetAppRoundedIcon />
              <FormattedMessageWrappedInSpan
                id="cartPage.productDownloadableOnly"
                defaultMessage="Digital product"
              />
              {props.type === 'billItem' && props.onToggleDvdDialog && (
                <HelpRoundedIcon className="icon-button grey" />
              )}
            </div>
          )}
          {props.title.type === DvdMsg && (
            <div className="cart-text-with-icon cart-product-shipping-info cart-typography_product-shipping">
              <LocalShippingRoundedIcon />
              <FormattedMessageWrappedInSpan
                id="cartPage.productFreeShipphing"
                defaultMessage="Free shipping"
              />
            </div>
          )}
          <div className="cart-overview-item-details_action">
            {props.hasDeleteOption && (
              <button className="icon-button grey" onClick={props.onDeleteClick}>
                {props.isLoading ? (
                  <CircularProgress size={20} thickness={4} />
                ) : (
                  <DeleteRoundedIcon />
                )}
              </button>
            )}
            {props.hasAddToCartOption && (
              <button className="icon-button grey" onClick={props.onAddToCartClick}>
                {props.isLoading ? (
                  <CircularProgress size={20} thickness={4} />
                ) : (
                  <>
                    <ShoppingCartRoundedIcon className="cart-add-icon-cart" />
                    <AddRoundedIcon className="cart-add-icon-plus" />
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

CartItem.defaultProps = {
  hasAddToCartOption: false,
  hasDeleteOption: true,
  hasHelpIcon: false,
  isLoading: false,
  subtitleHasHelpIcon: false,
  type: 'defaultItem',
  isDigital: false,
};

CartItem.propTypes = {
  discount: PropTypes.number,
  eventNames: PropTypes.array,
  finalPrice: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
  hasAddToCartOption: PropTypes.bool,
  hasDeleteOption: PropTypes.bool,
  hasHelpIcon: PropTypes.bool,
  icon: PropTypes.node,
  isLoading: PropTypes.bool,
  onAddToCartClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onHelpClick: PropTypes.func,
  onSubtitleHelpClick: PropTypes.func,
  originalPrice: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  subtitle: PropTypes.node,
  subtitleHasHelpIcon: PropTypes.bool,
  title: PropTypes.node.isRequired,
  vat: PropTypes.number,
  type: PropTypes.string,
  onToggleDvdDialog: PropTypes.func,
  isDigital: PropTypes.bool,
};

export default CartItem;
