import en from './en/en.json';
import contact_en from './en/contact-en.json';
import legal_en from './en/legal-en.json';
import cookiesInfo_en from './en/cookies-info-en.json';
import agb_en from './en/agb-en.json';
import privacy_en from './en/privacy-en.json';
import about_us_en from './en/about-us-en.json';
import jobs_en from './en/jobs-en.json';
import marathonFotoVariantsForSg_en from './en/marathonFotoVariantsForSg-en.json';

import de from './de/de.json';
import contact_de from './de/contact-de.json';
import legal_de from './de/legal-de.json';
import cookiesInfo_de from './de/cookies-info-de.json';
import agb_de from './de/agb-de.json';
import privacy_de from './de/privacy-de.json';
import about_us_de from './de/about-us-de.json';
import jobs_de from './de/jobs-de.json';

import fr from './fr/fr.json';
import contact_fr from './fr/contact-fr.json';
import about_us_fr from './fr/about-us-fr.json';
import privacy_fr from './fr/privacy-fr.json';

import es from './es/es.json';
import contact_es from './es/contact-es.json';
import legal_es from './es/legal-es.json';
import cookiesInfo_es from './es/cookies-info-es.json';
import about_us_es from './es/about-us-es.json';

import zh from './zh/zh.json';
import contact_zh from './zh/contact-zh.json';
import about_us_zh from './zh/about-us-zh.json';

import nl from './nl/nl.json';
import contact_nl from './nl/contact-nl.json';
import legal_nl from './nl/legal-nl.json';
import cookiesInfo_nl from './nl/cookies-info-nl.json';
import about_us_nl from './nl/about-us-nl.json';

import it from './it/it.json';
import contact_it from './it/contact-it.json';
import legal_it from './it/legal-it.json';
import cookiesInfo_it from './it/cookies-info-it.json';
import about_us_it from './it/about-us-it.json';

import pt from './pt/pt.json';
import contact_pt from './pt/contact-pt.json';
// import legal_pt from './pt/legal-pt.json';
import cookiesInfo_pt from './pt/cookies-info-pt.json';
import about_us_pt from './pt/about-us-pt.json';

import ru from './ru/ru.json';
import contact_ru from './en/contact-en.json';
import legal_ru from './en/legal-en.json';
import cookiesInfo_ru from './en/cookies-info-en.json';
import agb_ru from './en/agb-en.json';
import privacy_ru from './en/privacy-en.json';
import about_us_ru from './en/about-us-en.json';
import jobs_ru from './en/jobs-en.json';

import ja from './ja/ja.json';
import contact_ja from './en/contact-en.json';
import legal_ja from './en/legal-en.json';
import cookiesInfo_ja from './en/cookies-info-en.json';
import agb_ja from './en/agb-en.json';
import privacy_ja from './en/privacy-en.json';
import about_us_ja from './en/about-us-en.json';
import jobs_ja from './en/jobs-en.json';

import { getFromLocalStorage } from '../utils/browser-storage';
import sortBy from 'lodash.sortby';

// This is the available languages table.
// new languages should be added here as well.
export const languages = [
  {
    title: 'English',
    iso: 'en',
    index: 0,
  },
  {
    title: 'Deutsch',
    iso: 'de',
    index: 1,
  },
  {
    title: 'Francais',
    iso: 'fr',
    index: 2,
  },
  {
    title: 'Nederlands',
    iso: 'nl',
    index: 3,
  },
  {
    title: 'Italiano',
    iso: 'it',
    index: 4,
  },
  // {
  //   title: 'Polski',
  //   iso: 'pl',
  //   index: 5,
  // },
  {
    title: 'Español',
    iso: 'es',
    index: 6,
  },
  // {
  //   title: 'Svenska',
  //   iso: 'sv',
  //   index: 7,
  // },
  {
    title: '中文',
    iso: 'zh',
    index: 8,
  },
  {
    title: 'Português',
    iso: 'pt',
    index: 9,
  },
  {
    title: 'Русский',
    iso: 'ru',
    index: 10,
  },
  {
    title: 'やまと',
    iso: 'ja',
    index: 11,
  },
];

export const translations = {
  en: {
    ...en,
    ...contact_en,
    ...legal_en,
    ...cookiesInfo_en,
    ...agb_en,
    ...privacy_en,
    ...about_us_en,
    ...jobs_en,
  },
  de: {
    ...de,
    ...contact_de,
    ...legal_de,
    ...cookiesInfo_de,
    ...agb_de,
    ...privacy_de,
    ...about_us_de,
    ...jobs_de,
  },
  fr: {
    ...fr,
    ...contact_en,
    ...legal_en,
    ...cookiesInfo_en,
    ...agb_en,
    ...privacy_fr,
    ...jobs_en,
    ...about_us_fr,
  },
  es: {
    ...es,
    ...contact_es,
    ...legal_es,
    ...cookiesInfo_es,
    ...agb_en,
    ...privacy_en,
    ...jobs_en,
    ...about_us_es,
  },
  zh: {
    ...zh,
    ...contact_zh,
    ...legal_en,
    ...cookiesInfo_en,
    ...agb_en,
    ...privacy_en,
    ...jobs_en,
    ...about_us_zh,
  },
  nl: {
    ...nl,
    ...contact_nl,
    ...legal_nl,
    ...cookiesInfo_nl,
    ...agb_en,
    ...privacy_en,
    ...jobs_en,
    ...about_us_nl,
  },
  it: {
    ...it,
    ...contact_it,
    ...legal_it,
    ...cookiesInfo_it,
    ...agb_en,
    ...privacy_en,
    ...jobs_en,
    ...about_us_it,
  },
  pt: {
    ...pt,
    ...contact_en,
    ...legal_en,
    ...cookiesInfo_pt,
    ...agb_en,
    ...privacy_en,
    ...jobs_en,
    ...about_us_pt,
  },
  ru: {
    ...ru,
    ...contact_ru,
    ...legal_ru,
    ...cookiesInfo_ru,
    ...agb_ru,
    ...privacy_ru,
    ...about_us_ru,
    ...jobs_ru,
  },
  ja: {
    ...ja,
    ...contact_ja,
    ...legal_ja,
    ...cookiesInfo_ja,
    ...agb_ja,
    ...privacy_ja,
    ...about_us_ja,
    ...jobs_ja,
  },
};

export const marathonFotoTranslations = {
  en: {
    ...marathonFotoVariantsForSg_en,
  },
  de: {
    ...marathonFotoVariantsForSg_en,
  },
  fr: {
    ...marathonFotoVariantsForSg_en,
  },
  es: {
    ...marathonFotoVariantsForSg_en,
  },
  zh: {
    ...marathonFotoVariantsForSg_en,
  },
  nl: {
    ...marathonFotoVariantsForSg_en,
  },
  it: {
    ...marathonFotoVariantsForSg_en,
  },
  pt: {
    ...marathonFotoVariantsForSg_en,
  },
  ru: {
    ...marathonFotoVariantsForSg_en,
  },
  ja: {
    ...marathonFotoVariantsForSg_en,
  },
};

/**
 * Check whether the translation for the specified language exist or not
 * @param  {string} langCode The language code (en, de, fr) to check
 * @return {boolean} Returns true if language is supported else false.
 */
export function isLanguageSupported(langCode) {
  let exists = false;
  for (let i = 0; i < languages.length; i++) {
    const language = languages[i];
    if (language.iso === langCode) {
      exists = true;
    }
  }
  return exists;
}

export function getAppLanguage() {
  // NOTE: In our app we use only 2 letters language descriptions
  //
  // Try to get the language from the local storage
  let languageFromLocalStorage = getFromLocalStorage('language');
  if (languageFromLocalStorage) {
    return languageFromLocalStorage;
  }

  // If no language is saved in the local storage get the browsers preferred language
  // Appsignal has reported quite a few bugs where the app wanted to access the substring
  // from a value which is undefined.
  if (navigator) {
    if (navigator.language) {
      if (isLanguageSupported(navigator.language.substring(0, 2))) {
        return navigator.language.substring(0, 2);
      }
    }

    if (navigator.userLanguage) {
      if (isLanguageSupported(navigator.userLanguage.substring(0, 2))) {
        return navigator.userLanguage.substring(0, 2);
      }
    }
  }
  // By default return english
  return 'en';
}

// By default this function return en_US
export function getLocaleWithCountryCode(language) {
  switch (language) {
    case 'de':
      return 'de-DE';

    case 'nl':
      return 'nl-NL';

    case 'fr':
      return 'fr-FR';

    case 'it':
      return 'it-IT';

    case 'pl':
      return 'pl-PL';

    case 'es':
      return 'es-ES';

    case 'sv':
      return 'sv-SE';

    case 'zh':
      return 'zh-CN';

    case 'pt':
      return 'pt-PT';

    case 'ru':
      return 'ru-RU';

    case 'ja':
      return 'ja-JP';

    default:
      return 'en-GB';
  }
}

export function getCountryNameByAlpha2(alpha2_code, locale) {
  let i18n_countries = require('i18n-iso-countries');
  locale = locale == undefined ? getAppLanguage() : locale;
  i18n_countries.registerLocale(require('i18n-iso-countries/langs/' + locale + '.json'));
  return i18n_countries.getName(alpha2_code, locale);
}

export function getLocalizedCountries(countries) {
  let locale = getAppLanguage();

  if (countries) {
    return countries.map((country) => ({
      alpha2: country.alpha2,
      name: getCountryNameByAlpha2(country.alpha2, locale),
    }));
  } else {
    return countries;
  }
}
