/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CartLineItemFotoFlat_lineItem$ref = any;
type CartLineItemGiftcard_lineItem$ref = any;
type CartLineItemSinglePhoto_lineItem$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartLineItem_lineItem$ref: FragmentReference;
declare export opaque type CartLineItem_lineItem$fragmentType: CartLineItem_lineItem$ref;
export type CartLineItem_lineItem = {|
  +product: ?{|
    +type: ?string
  |},
  +$fragmentRefs: CartLineItemFotoFlat_lineItem$ref & CartLineItemSinglePhoto_lineItem$ref & CartLineItemGiftcard_lineItem$ref,
  +$refType: CartLineItem_lineItem$ref,
|};
export type CartLineItem_lineItem$data = CartLineItem_lineItem;
export type CartLineItem_lineItem$key = {
  +$data?: CartLineItem_lineItem$data,
  +$fragmentRefs: CartLineItem_lineItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CartLineItem_lineItem",
  "type": "LineItem",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "product",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CartLineItemFotoFlat_lineItem",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CartLineItemSinglePhoto_lineItem",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CartLineItemGiftcard_lineItem",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f66dbea7a9eb784a4752660d9c6ccefc';

module.exports = node;
