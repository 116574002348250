import React, { useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';

import { FormattedMessageWrappedInSpan } from '../../misc';
import { DisabledToActiveButton } from '../../misc/Buttons';
import { PRESALE_EVENT_ON_WITH_ACTION } from '../../../utils/variables';

const PresaleEventInfo = (props) => {
  const { onShowRegularSearch, eventPresaleInfo } = props;
  const [showAdditionalSupportText, setShowAdditionalSupportText] = useState(false);

  return (
    <div className="col-xs-16 col-sm-6">
      <div className="event-page-search-container">
        <div className="event-page-search-container-label ml-15 mr-15">
          <FormattedMessageWrappedInSpan
            id="presaleEventInfo.didYouBuyQuestion.title"
            defaultMessage="Presale event"
          />
        </div>
        {!showAdditionalSupportText && (
          <div className="ml-15 mr-15 mt-15 mb-30">
            <FormattedMessageWrappedInSpan
              id="presaleEventInfo.didYouBuyQuestion.question"
              defaultMessage="Did you buy your photos when you signed up for the event?"
            />
          </div>
        )}
        {showAdditionalSupportText && (
          <div
            className="ml-15 mr-15 mt-15 mb-30"
            dangerouslySetInnerHTML={{
              __html: eventPresaleInfo.presaleMessage,
            }}
          />
        )}
        {!showAdditionalSupportText && (
          <div className="event-page-search-container-double-btns">
            <button
              className="btn-default warning-btn full-width"
              onClick={onShowRegularSearch}
            >
              <FormattedMessageWrappedInSpan
                id="presaleEventInfo.didYouBuyQuestion.answerNo"
                defaultMessage="No"
              />
            </button>
            <button
              className="btn-default primary-new full-width"
              onClick={() => setShowAdditionalSupportText(true)}
            >
              <FormattedMessageWrappedInSpan
                id="presaleEventInfo.didYouBuyQuestion.answerYes"
                defaultMessage="Yes"
              />
            </button>
          </div>
        )}
        {showAdditionalSupportText && (
          <DisabledToActiveButton
            className="btn-default primary-new full-width"
            onClick={onShowRegularSearch}
          >
            {eventPresaleInfo.presale === PRESALE_EVENT_ON_WITH_ACTION ? (
              <FormattedMessageWrappedInSpan
                id="presaleEventInfo.buttons.continue"
                defaultMessage="Continue"
              />
            ) : (
              <FormattedMessageWrappedInSpan
                id="presaleEventInfo.buttons.continueAnyway"
                defaultMessage="Continue anyway"
              />
            )}
          </DisabledToActiveButton>
        )}
      </div>
    </div>
  );
};

PresaleEventInfo.propTypes = {
  onShowRegularSearch: PropTypes.func,
  eventPresaleInfo: PropTypes.object,
};

export { PresaleEventInfo };

export default createFragmentContainer(PresaleEventInfo, {
  eventPresaleInfo: graphql`
    fragment PresaleEventInfo_eventPresaleInfo on Event {
      presaleMessage
      presale
    }
  `,
});
