import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';

import AlbumOutlinedIcon from '@material-ui/icons/AlbumOutlined';
import CartAdditionalProduct from './CartAdditionalProduct';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { DvdMsg } from '../../messages';

function CartAdditionalProductDvd(props) {
  const { additionalProduct, eventNames, ...other } = props;
  return (
    <CartAdditionalProduct
      additionalProduct={additionalProduct}
      hasHelpIcon={true}
      icon={
        <div className="cart-item-icon-container cart-item-icon-container_flex col-sm-6 col-xs-5">
          <AlbumOutlinedIcon className="cart-item-icon-container_icon-img" />
        </div>
      }
      title={<DvdMsg />}
      subtitle={
        <FormattedMessageWrappedInSpan
          id="cartProductDvd.subtitle"
          defaultMessage="All of the photos from this order on the DVD"
        />
      }
      eventNames={eventNames}
      {...other}
    />
  );
}

CartAdditionalProductDvd.propTypes = {
  additionalProduct: PropTypes.object,
  eventNames: PropTypes.object,
};

export { CartAdditionalProductDvd };

export default createFragmentContainer(CartAdditionalProductDvd, {
  additionalProduct: graphql`
    fragment CartAdditionalProductDvd_additionalProduct on Product {
      ...CartAdditionalProduct_additionalProduct
    }
  `,
});
