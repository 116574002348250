import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import FormattedPrice from '../../misc/FormattedPrice';
import { FOTO_FLAT, SINGLE_PHOTO, PHOTO_DVD, PHOTO_BOOK, GIFTCARD } from '../../../utils/variables';
import { FotoFlatMsg, DvdMsg, PhotoBookMsg, SinglePhotoMsg, GiftcardMessage } from '../../messages';
import classNames from 'classnames';

import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

const CartTotal = (props) => {
  const determineProductName = (productType) => {
    switch (productType) {
      case FOTO_FLAT:
        return <FotoFlatMsg />;
      case SINGLE_PHOTO:
        return <SinglePhotoMsg />;
      case PHOTO_DVD:
        return <DvdMsg />;
      case PHOTO_BOOK:
        return <PhotoBookMsg />;
      case GIFTCARD:
        return <GiftcardMessage />;
      default:
        return '';
    }
  };

  const determineProductSubtitle = (productType, productData) => {
    // giftcard has no params as a product so it is added as additional condition
    if (productData && productType || productType === GIFTCARD) {
      switch (productType) {
        case FOTO_FLAT:
          return (
            <FormattedMessageWrappedInSpan
              id="cartProductFotoFlat.subtitle"
              defaultMessage="All photos for bib number {startNumber}"
              values={{ startNumber: productData.startnumber }}
            />
          );
        case SINGLE_PHOTO:
          return (
            <FormattedMessageWrappedInSpan
              id="cartProductSingleFoto.subtitle"
              defaultMessage="Photo ID {photoID}"
              values={{ photoID: productData.foto.sgId }}
            />
          );
        case PHOTO_DVD:
          return (
            <FormattedMessageWrappedInSpan
              id="cartProductDvd.subtitle"
              defaultMessage="All of the photos from this order on the DVD"
            />
          );
        case PHOTO_BOOK:
          return (
            <FormattedMessageWrappedInSpan
              id="cartLineItemPhotoBook.additionalShipping"
              defaultMessage="Shipping costs not included"
            />
          );
        case GIFTCARD:
          return (
            <FormattedMessageWrappedInSpan
              id="cartLineItemPhotoBook.giftcardDescription"
              defaultMessage="Sharable code to redeem one package"
            />
          );
        default:
          return '';
      }
    }
  };

  const hasPhotoBook = (items) => {
    if (items) {
      return Boolean(items.find((element) => element.product.type === PHOTO_BOOK));
    } else {
      return false;
    }
  };

  return (
    <div
      className={classNames('cart-field cart-dark-field cart-order-summary', {
        'mt-20': props.componentVersion === 'basic',
      })}
    >
      {props.componentVersion === 'extended' && (
        <>
          <h1>
            <FormattedMessageWrappedInSpan
              id="cartPage.orderSummaryTitle"
              defaultMessage="Order summary"
            />
          </h1>
          {props.lineItems &&
            props.lineItems.map((itemInCart) => {
              return (
                <div className="row summary-item " key={`summary-item-${itemInCart.id}`}>
                  <div className="col-xs-12 summary-item_details">
                    <span className="cart-typography_product-name">
                      {determineProductName(itemInCart.product.type)}
                    </span>
                    <span className="cart-typography_description-small">
                      {determineProductSubtitle(
                        itemInCart.product.type,
                        itemInCart.productParams
                      )}
                    </span>
                  </div>
                  <div className="col-xs-4 summary-item_price">
                    <span className="cart-typography_product-name">
                      {itemInCart.price && <FormattedPrice price={itemInCart.price} />}
                    </span>
                  </div>
                </div>
              );
            })}
        </>
      )}
      {props.deliveryCharges && (
        <div className="row summary-item ">
          <div className="col-xs-12 summary-item_details">
            <span className="cart-typography_product-name">
              <FormattedMessageWrappedInSpan
                id="cartTotal.delivery"
                defaultMessage="Shipping"
              />
            </span>
          </div>
          <div className="col-xs-4 summary-item_price">
            <span className="cart-typography_product-name">
              {props.deliveryCharges?.amount === 0 ? (
                <FormattedMessageWrappedInSpan
                  id="cartTotal.deliveryFree"
                  defaultMessage="Free"
                />
              ) : (
                <FormattedPrice price={props.deliveryCharges} />
              )}
            </span>
          </div>
        </div>
      )}
      <hr />
      <div className="summary-total">
        <div>
          <FormattedMessageWrappedInSpan
            id="cartPage.cartTotal"
            defaultMessage="Total"
            className="cart-typography_cart-total"
          />
          <span className="cart-typography_cart-total">
            {props.totalPrice && <FormattedPrice price={props.totalPrice} />}
          </span>
        </div>
        {hasPhotoBook(props.lineItems) &&
          !props.deliveryCharges &&
          props.componentVersion === 'extended' && (
            <div
              className="cart-text-with-icon"
              onClick={() => props.onOpenPaymentNotification()}
            >
              <FormattedMessageWrappedInSpan
                id="cartPage.cartPhotoBookShippingInfo"
                defaultMessage="+ Shipping"
                className="cart-typography_shipping-info"
              />
              <HelpRoundedIcon className="icon-button grey" />
            </div>
          )}
        {props.componentVersion === 'extended' && (
          <FormattedMessageWrappedInSpan
            id="cartPage.cartCouponGiftcardInfo"
            defaultMessage="Have a <span>coupon</span> or a <span>gifcard</span>? Apply it in the Payment step"
            values={{
              span: (chunks) => (
                <span className="cart-typography_text-highlight">{chunks}</span>
              ),
            }}
          />
        )}
      </div>
    </div>
  );
};

CartTotal.defaultProps = {
  componentVersion: 'basic',
};

CartTotal.propTypes = {
  viewer: PropTypes.object,
  lineItems: PropTypes.array,
  deliveryCharges: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  totalPrice: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  onOpenPaymentNotification: PropTypes.func,
  componentVersion: PropTypes.string,
};

export default CartTotal;
