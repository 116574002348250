import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';

import CartItem from './CartItem';
import GiftcardMessage from '../../messages/GiftcardMsg';

const CartLineItemGiftcard = (props) => {
  const { lineItem, ...other } = props;

  const eventOfGiftcard = lineItem.product.event;

  return (
    <CartItem
      eventNames={[eventOfGiftcard.name]}
      icon={
        <div className="cart-item-icon-container cart-item-icon-container_flex col-xs-5 col-sm-6">
          <CardGiftcardIcon className="cart-item-icon-container_icon-img" />
        </div>
      }
      lineItem={lineItem}
      title={<GiftcardMessage />}
      isDigital={true}
      {...other}
    />
  );
};

CartLineItemGiftcard.propTypes = {
  lineItem: PropTypes.object,
};

export { CartLineItemGiftcard };

export default createFragmentContainer(CartLineItemGiftcard, {
  lineItem: graphql`
    fragment CartLineItemGiftcard_lineItem on LineItem {
      product {
        price
        label
        event {
          name
          banner
        }
      }
    }
  `,
});
