import React, { Fragment, useState, useEffect } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import cloneDeep from 'lodash.clonedeep';
import classNames from 'classnames';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CustomDialog from '../dialogs/CustomDialog';
import FormattedPrice from '../../misc/FormattedPrice';
import EmbeddedVideo from '../../misc/EmbeddedVideo';
import ItemAddedDialog from '../dialogs/ItemAddedDialog';

import { SINGLE_PHOTO, PHOTO_DVD } from '../../../utils/variables';
import {
  includesVideo,
  includesSinglePhoto,
  includesPhotoDvd,
  includesCertificate,
  includesGiftcard,
  getVideo,
  getCertificate,
  getFotoFlat,
  getGiftcard,
} from '../../../utils/common';
import AddGiftcardToCartMutation from '../../../mutations/AddGiftcardToCartMutation';
import withRequestHandler from '../../hoc/withRequestHandler';
import { trackEvent } from '../../../utils/ga-tracking';

////////////////////////////////////////////
// TODO missing tests cases:
// resolve mock relay operation with updater
////////////////////////////////////////////

const Pricelist = (props) => {
  const { offer, onScrollToSearchForm, onShowHowToBuyHint } = props;
  const [showVoucherDialog, setShowVoucherDialog] = useState(false);
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [showCertificateDialog, setShowCertificateDialog] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const [certificatePreviewUrl, setCertificatePreviewUrl] = useState();
  const [updatedCart, setUpdatedCart] = useState(null);
  const [showItemAddedDialog, setShowItemAddedDialog] = useState(false);

  const productsClone = cloneDeep(offer.products);
  const fotoFlat = getFotoFlat(productsClone);
  // After the FotoFlat has been removed from the list of products, the productsClone
  // list contains all further products (in case only the FotoFlat is offered, the list
  // will be empty).
  const showOtherProducts =
    includesSinglePhoto(productsClone) || includesPhotoDvd(productsClone);
  const hasVideo = includesVideo(offer.products);
  const hasCertificates = includesCertificate(offer.products);
  const hasGiftcard = includesGiftcard(productsClone);
  const giftcard = getGiftcard(offer.products);

  const getPreviewPhotoForCertificate = (products) => {
    return getCertificate(products).configuration.previewImageUrl;
  };

  const handleCloseVoucherDialog = () => {
    setShowVoucherDialog(false);
  };

  const handleCloseVideoDialog = () => {
    setShowVideoDialog(false);
  };

  const handleCloseCertificateDialog = () => {
    setShowCertificateDialog(false);
  };

  const shouldCenterOfffer = (fotoFlat, allProducts) => {
    if (fotoFlat && allProducts.length === 1) {
      return true;
    }
    if (!fotoFlat && allProducts.length > 0) {
      return true;
    }
    return false;
  };

  const getVideoUrl = (offer) => {
    const videoUrl = getVideo(offer).configuration?.previewVideoUrl;
    return videoUrl;
  };

  const centerOffer = shouldCenterOfffer(fotoFlat, productsClone);

  useEffect(() => {
    if (hasVideo) {
      setVideoUrl(getVideoUrl(offer.products));
    }
    if (hasCertificates) {
      setCertificatePreviewUrl(getPreviewPhotoForCertificate(offer.products));
    }
  }, [hasCertificates, hasVideo, offer.products]);

  const onBuyGiftcard = () => {
    props.onRequestStart();
    AddGiftcardToCartMutation.commit(
      props.relay.environment,
      giftcard.id, // product id
      (error, cart) => {
        if (error) {
          props.onRequestError(error);
        } else {
          setUpdatedCart(cart);
          setShowItemAddedDialog(true);
        }
        props.onRequestStop();
      }
    );
  };

  return (
    <Fragment>
      <div className="pricelist-container">
        <div className="row">
          <div className="col-xs-16 col-sm-12 col-sm-offset-2">
            <h1 className="separator">
              <FormattedMessageWrappedInSpan
                id="pricelist.title"
                defaultMessage="Offer"
              />
            </h1>
          </div>
        </div>
        <div
          className={classNames('row', {
            'center-sm': centerOffer,
          })}
        >
          {fotoFlat && (
            <div
              className={classNames('col-xs-16', 'col-sm-6', {
                'col-sm-offset-2': !centerOffer,
              })}
            >
              <div className="pricelist pricelist-primary">
                <div className="pricelist-header">
                  <FormattedMessageWrappedInSpan
                    id="global.fotoFlat"
                    defaultMessage="Foto-Flat"
                  />
                </div>
                <div className="pricelist-body">
                  <div className="pricelist-item">
                    <FormattedMessageWrappedInSpan
                      id="pricelist.personalPhotos"
                      defaultMessage="All personal digital photos (JPG)"
                    />
                  </div>
                  <div className="pricelist-item">
                    <FormattedMessageWrappedInSpan
                      id="pricelist.impressionPhotos"
                      defaultMessage="All impressions (JPG)"
                    />
                  </div>
                  {hasVideo && (
                    <div
                      className={classNames('pricelist-item pricelist-item-video', {
                        'pricelist-item-voucher_actionable': Boolean(videoUrl),
                      })}
                      onClick={() => {
                        if (videoUrl) {
                          setShowVideoDialog(true);
                        }
                      }}
                    >
                      <FormattedMessageWrappedInSpan
                        id="fotoFlatOffer.videoOffert"
                        defaultMessage="Impression video with <b>your personal finish scene</b> (MP4)"
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                      {videoUrl && <span className="icon eye" />}
                    </div>
                  )}
                  {hasCertificates && (
                    <div
                      className="pricelist-item pricelist-item_actionable"
                      onClick={() => {
                        setShowCertificateDialog(true);
                      }}
                    >
                      <FormattedMessageWrappedInSpan
                        id="fotoFlatOffer.certificatesOffert"
                        defaultMessage="Printable certificate (JPEG)"
                      />
                      <span className="icon eye" />
                    </div>
                  )}
                  {offer.voucherCampaigns.map((voucherCampaign) => {
                    return (
                      <div
                        key={voucherCampaign.id}
                        className="pricelist-item pricelist-item-voucher pricelist-item_actionable"
                        onClick={() => {
                          setShowVoucherDialog({ voucherCampaign: voucherCampaign });
                          trackEvent('Event', 'Voucher', 'Offer pricelist');
                        }}
                      >
                        <div
                          className="pricelist-item-voucher-text"
                          dangerouslySetInnerHTML={{
                            __html: voucherCampaign.shortText,
                          }}
                        />
                        <span className="icon eye" />
                      </div>
                    );
                  })}
                </div>
                <div
                  className="pricelist-main-price"
                  onClick={() => {
                    onScrollToSearchForm();
                    onShowHowToBuyHint(true);
                  }}
                >
                  <FormattedPrice price={fotoFlat.price} />
                </div>
              </div>
            </div>
          )}
          <div className="col-sm-6 col-xs-16">
            {showOtherProducts && (
              <div
                className={classNames('pricelist', {
                  'pricelist-first-row': hasGiftcard,
                })}
              >
                <div className="pricelist-header">
                  <FormattedMessageWrappedInSpan
                    id="pricelist.basicOfferTitle"
                    defaultMessage="Basic"
                  />
                </div>
                <div className="pricelist-body">
                  {productsClone.map((p) => {
                    if (p.type === SINGLE_PHOTO && !p.mediaCollection) {
                      return (
                        <div key={p.id} className="pricelist-item">
                          <FormattedMessageWrappedInSpan
                            id="pricelist.singlePhoto"
                            defaultMessage="1 personal digital photo (JPG)"
                          />
                          <span>
                            <FormattedPrice price={p.price} />
                          </span>
                        </div>
                      );
                    }
                    if (p.type === PHOTO_DVD) {
                      return (
                        <div key={p.id} className="pricelist-item">
                          <FormattedMessageWrappedInSpan
                            id="pricelist.additionalShipment"
                            defaultMessage="Optional: shipping of your order on DVD"
                          />
                          <span>
                            <FormattedPrice price={p.price} />
                          </span>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            )}
            {hasGiftcard && (
              <div className="pricelist">
                <div className="pricelist-header">
                  <FormattedMessageWrappedInSpan
                    id="pricelist.giftcardOffer"
                    defaultMessage="Giftcard"
                  />
                </div>
                <div className="pricelist-body">
                  <div className="pricelist-item">
                    <FormattedMessageWrappedInSpan
                      id="pricelist.giftcard"
                      defaultMessage="Sharable code to redeem one Foto-Flat from this event with all extras included"
                    />
                    <span>
                      <FormattedPrice price={fotoFlat.price} />
                    </span>
                  </div>
                </div>
                <button
                  className="pricelist-main-price pricelist-main-price_giftcard"
                  onClick={() => {
                    onBuyGiftcard();
                  }}
                >
                  <FormattedMessageWrappedInSpan
                    id="pricelist.giftcardAction"
                    defaultMessage="Buy as a gift"
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {offer.voucherCampaigns.map((voucherCampaign) => {
        return (
          <CustomDialog
            hasFixedWidth={false}
            open={
              showVoucherDialog &&
              showVoucherDialog.voucherCampaign.id == voucherCampaign.id
            }
            onRequestClose={handleCloseVoucherDialog}
          >
            <div className="notification-container">
              <DialogContent>
                <div className="notification-body">
                  <div dangerouslySetInnerHTML={{ __html: voucherCampaign.longText }} />
                  <div className="voucher-dialog-image">
                    <a
                      href={voucherCampaign.url}
                      onClick={() => trackEvent('Event', 'Voucher', 'Banner dialog')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={voucherCampaign.banner} />
                    </a>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className="notification-action">
                  <button className="accept" onClick={handleCloseVoucherDialog}>
                    <FormattedMessageWrappedInSpan
                      id="pricelist.dialog.acceptButton"
                      defaultMessage="Ok"
                    />
                  </button>
                </div>
              </DialogActions>
            </div>
          </CustomDialog>
        );
      })}
      {hasVideo && videoUrl && (
        <CustomDialog
          hasFixedWidth={false}
          open={showVideoDialog}
          onRequestClose={handleCloseVideoDialog}
        >
          <div className="notification-container notification-video">
            <DialogContent>
              <div className="notification-body ">
                <h1>
                  <FormattedMessageWrappedInSpan
                    id="pricelist.dialog.video.title"
                    defaultMessage="Video preview"
                  />
                </h1>
                <EmbeddedVideo videoUrl={videoUrl} />
              </div>
            </DialogContent>
            <DialogActions>
              <div className="notification-action">
                <button className="accept" onClick={handleCloseVideoDialog}>
                  <FormattedMessageWrappedInSpan
                    id="pricelist.dialog.acceptButton"
                    defaultMessage="Ok"
                  />
                </button>
              </div>
            </DialogActions>
          </div>
        </CustomDialog>
      )}
      {hasCertificates && (
        <CustomDialog
          hasFixedWidth={false}
          open={showCertificateDialog}
          onRequestClose={handleCloseCertificateDialog}
        >
          <div className="notification-container ">
            <DialogContent>
              <div className="notification-body certificate-body">
                <h1>
                  <FormattedMessageWrappedInSpan
                    id="pricelist.dialog.certificate.title"
                    defaultMessage="What is certificate?"
                  />
                </h1>
                {certificatePreviewUrl && (
                  <div className="certificate-dialog-image">
                    <img src={certificatePreviewUrl} alt="certificate preview" />
                  </div>
                )}
                <div className="pricelist-item-certificate-text">
                  <FormattedMessageWrappedInSpan
                    id="pricelist.dialog.certificate.text"
                    defaultMessage="You can create a certificate with a photo of your choice from the pictures of your Foto-Flat and with your personal data. You can download and print it."
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="notification-action">
                <button className="accept" onClick={handleCloseCertificateDialog}>
                  <FormattedMessageWrappedInSpan
                    id="pricelist.dialog.acceptButton"
                    defaultMessage="Ok"
                  />
                </button>
              </div>
            </DialogActions>
          </div>
        </CustomDialog>
      )}
      {updatedCart && (
        <ItemAddedDialog
          open={showItemAddedDialog}
          lineItems={updatedCart.lineItems}
          totalPrice={updatedCart.totalPrice}
          onRequestClose={() => setShowItemAddedDialog(false)}
        />
      )}
    </Fragment>
  );
};

Pricelist.propTypes = {
  offer: PropTypes.object,
  onScrollToSearchForm: PropTypes.func,
  onShowHowToBuyHint: PropTypes.func,
};

export { Pricelist };

const WrappedComponent = withRequestHandler(Pricelist);

export default createFragmentContainer(WrappedComponent, {
  offer: graphql`
    fragment Pricelist_offer on Event {
      products {
        id
        mediaCollection {
          name
        }
        price
        type
        configuration {
          ... on SingleVideoConfiguration {
            previewVideoUrl
          }
          ... on EventCertificateConfiguration {
            previewImageUrl
          }
        }
      }
      voucherCampaigns {
        id
        banner
        longText
        name
        shortText
        url
      }
    }
  `,
});
