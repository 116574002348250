import React, { useEffect } from 'react';
import classNames from 'classnames';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { useHistory, useParams } from 'react-router-dom';
import { stringToUrl } from '../../../utils/common';

const JobsPageItem = ({
  showJob,
  setShowJob,
  image,
  job,
  JobComponent,
  setJob,
  jobType,
  title,
  titleEn,
  intro,
  introEn,
  jobData,
  language,
  ...props
}) => {
  const history = useHistory();
  const { position } = useParams();
  const jobTitleUrl = stringToUrl(jobData.title);

  useEffect(() => {
    if (position && position === jobTitleUrl) {
      openJobAd();
    }
  }, [position]);

  const addJobTitleToUrl = () => {
    history.push(`/${language}/jobs/${jobTitleUrl}`);
  };

  const openJobAd = () => {
    setJob(jobType);
    setShowJob(true);
  };

  const closeJobAd = () => {
    history.push(`/${language}/jobs`);
    setShowJob(false);
    setJob('');
  };

  const backButton = language === 'de' ? 'Zurück' : 'Back';
  const showJobButton = language === 'de' ? 'Zeige Job' : 'Show Job';
  const remoteHint =
    language === 'de' ? 'mit Option zum mobilen Arbeiten' : 'option for mobile work';

  return (
    <>
      {showJob ? (
        <>
          {job == jobType && (
            <div className="container-960 container-page job-ad">
              <div className="job-ad-back" onClick={closeJobAd}>
                <KeyboardBackspaceRoundedIcon /> {backButton}
              </div>
              <h2>{jobData.title}</h2>
              <h3>{jobData.intro}</h3>
              {jobData.aboutus && (
                <div>
                  <h4>{jobData.aboutus.title}</h4>
                  <span dangerouslySetInnerHTML={{ __html: jobData.aboutus.text }} />
                </div>
              )}
              {jobData.yourrole && (
                <div>
                  <h4>{jobData.yourrole.title}</h4>
                  <ul>
                    {Object.entries(jobData.yourrole).map((jobRoll) => {
                      if (jobRoll[0] != 'title') {
                        return <li>{jobRoll[1]}</li>;
                      }
                    })}
                  </ul>
                </div>
              )}
              {jobData.needs && (
                <div>
                  <h4>{jobData.needs.title}</h4>
                  <ul>
                    {Object.entries(jobData.needs).map((need) => {
                      if (need[0] != 'title') {
                        return <li>{need[1]}</li>;
                      }
                    })}
                  </ul>
                </div>
              )}
              {jobData.offer && (
                <div>
                  <h4>{jobData.offer.title}</h4>
                  <ul>
                    {Object.entries(jobData.offer).map((offer) => {
                      if (offer[0] != 'title') {
                        return <li>{offer[1]}</li>;
                      }
                    })}
                  </ul>
                </div>
              )}
              {jobData.process && (
                <div>
                  <h4>{jobData.process.title}</h4>
                  <ul>
                    {Object.entries(jobData.process).map((step) => {
                      if (step[0] != 'title') {
                        return <li>{step[1]}</li>;
                      }
                    })}
                  </ul>
                </div>
              )}
              {jobData.redirection && (
                <div className="job-ad-redirection">
                  {Object.entries(jobData.redirection).map((redirectionInstruction) => {
                    if (redirectionInstruction[0] != 'link') {
                      return <span>{redirectionInstruction[1]}</span>;
                    } else {
                      return (
                        <a
                          href="https://sites.google.com/sportograf.com/freelancer-sportograf/home"
                          target="_blank"
                        >
                          <span>{redirectionInstruction[1]} </span>{' '}
                          <MailOutlineRoundedIcon />
                        </a>
                      );
                    }
                  })}
                </div>
              )}

              {jobData.contact && (
                <div>
                  <h4>{jobData.contact.title}</h4>

                  {Object.entries(jobData.contact).map((contact) => {
                    if (contact[0] != 'title') {
                      return (
                        <span className="job-ad-contact-item">
                          {contact[0] === 'contact' && <MailOutlineRoundedIcon />}
                          {contact[0] === 'address' && <RoomRoundedIcon />}
                          {contact[1]}
                        </span>
                      );
                    }
                  })}
                </div>
              )}
              <button className="secondary" onClick={closeJobAd}>
                {backButton}
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="event-list-item-container col-xs-8 col-sm-4">
            <div
              className="event-list-item-container-inner"
              onClick={() => {
                addJobTitleToUrl();
              }}
            >
              <div
                className={classNames(`event-list-item-banner job-preview`, {
                  'banner-gray-scale': false,
                })}
                style={{
                  backgroundImage: `url(${image})`,
                }}
              ></div>
              {jobData.remote && <span className="job-ad-remote">{remoteHint}</span>}
              {jobData.titleEn && language != 'de' && (
                <span className="job-ad-german"></span>
              )}
              <div className="event-list-item-body job-preview-body">
                <span className="event-list-item-name job-preview-title">
                  {titleEn && language != 'de' ? titleEn : title}
                </span>
                <p> {introEn && language != 'de' ? introEn : intro}</p>
              </div>
              <div
                className={classNames(
                  'event-list-item-footer',
                  'event-list-item-footer-action'
                )}
              >
                <button className="redirect" onClick={addJobTitleToUrl}>
                  {showJobButton}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default JobsPageItem;
