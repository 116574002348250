import find from 'lodash.find';

import scrollToComponent from 'react-scroll-to-component';

import {
  FOTO_FLAT,
  SINGLE_PHOTO,
  PHOTO_DVD,
  PHOTO_BOOK,
  VIDEO,
  CERTIFICATE,
  PAYPAL,
  ADYEN,
  AMAZON,
  LAYOUT_BREAKPOINT,
  GIFTCARD,
} from './variables';

/****************************************************************************************

                                        VARIANTS

*****************************************************************************************/

export function getPhotoVariant(variants, name) {
  return find(variants, (v) => v.name === name);
}

/****************************************************************************************

                                        LINE ITEMS

*****************************************************************************************/

export function getLineItem(lineItems, type) {
  return find(lineItems, (li) => li.product.type === type);
}

export function getLineItemById(lineItems, id) {
  return find(lineItems, (li) => li.product.id === id);
}

/****************************************************************************************

                                        PRODUCTS

*****************************************************************************************/

export function getProduct(products, type) {
  // In the old system, a distinction was made between single photos belonging to a
  // certain category and photos that do not. The photos that belonged to a specific
  // category were cheaper than the others. In the new system all single photos have the
  // same price.
  if (type === SINGLE_PHOTO) {
    return find(products, (p) => p.type === type && !p.mediaCollection);
  }
  return find(products, (p) => p.type === type);
}

export function getFotoFlat(products) {
  return getProduct(products, FOTO_FLAT);
}

export function getSinglePhoto(products) {
  return getProduct(products, SINGLE_PHOTO);
}

export function getCertificate(products) {
  return getProduct(products, CERTIFICATE);
}

export function getPhotoDvd(products) {
  return getProduct(products, PHOTO_DVD);
}

export function getPhotoBook(products) {
  return getProduct(products, PHOTO_BOOK);
}

export function getVideo(products) {
  return getProduct(products, VIDEO);
}

export function getGiftcard(products) {
  return getProduct(products, GIFTCARD);
}

export function includesFotoFlat(products) {
  return Boolean(getFotoFlat(products));
}

export function includesSinglePhoto(products) {
  return Boolean(getSinglePhoto(products));
}

export function includesCertificate(products) {
  return Boolean(getCertificate(products));
}

export function includesPhotoDvd(products) {
  return Boolean(getPhotoDvd(products));
}

export function includesPhotoBook(products) {
  return Boolean(getPhotoBook(products));
}

export function includesVideo(products) {
  return Boolean(getVideo(products));
}

export function includesGiftcard(products) {
  return Boolean(getGiftcard(products));
}

/****************************************************************************************

                                        PAYMENT METHODS

*****************************************************************************************/

export function getPaymentMethod(paymentMethods, name) {
  return find(paymentMethods, (pm) => pm.name === name);
}

export function getPaymentMethodsByType(paymentMethods, type) {
  return find(paymentMethods, (pm) => pm.defaultPaymentType === type);
}

export function getSelectedPaymentMethod(availablePaymentMethods, order) {
  if (order.payment) {
    return getPaymentMethod(availablePaymentMethods, order.payment.paymentMethod.name);
  } else {
    return undefined;
  }
}

export function includesPayPal(paymentMethods) {
  return Boolean(getPaymentMethodsByType(paymentMethods, PAYPAL));
}

export function includesAdyen(paymentMethods) {
  return Boolean(getPaymentMethodsByType(paymentMethods, ADYEN));
}

export function includesAmazon(paymentMethods) {
  return Boolean(getPaymentMethodsByType(paymentMethods, AMAZON));
}

/****************************************************************************************

                                        PATH/URL

*****************************************************************************************/

export function pathHasLoginToken(path) {
  if (path) {
    if (path.split('=')[0] === '?login_token') {
      return true;
    }
  }
  return false;
}

export function getLoginTokenFromPath(path) {
  // Some services, for example Facebook, might append another parameter to the URL
  const queryStringParams = path.split('&');
  return queryStringParams[0].split('=')[1];
}

export function getLanguageFromPath(path) {
  const language = path.split('/')[1];
  return language ? language : '';
}

export function pathHasReminderToken(path) {
  if (path) {
    if (path.split('=')[0] === '?reminder_accepted') {
      return true;
    }
  }
  return false;
}

export function getReminderTokenFromPath(path) {
  // Some services, for example Facebook, might append another parameter to the URL
  const queryStringParams = path.split('&');
  return queryStringParams[0].split('=')[1];
}

export function getMainSearchResultUrl(
  eventSgId,
  strartnumber,
  eventHasSecondFactor,
  customerKey
) {
  if (!strartnumber) {
    return `/event/${eventSgId}/`;
  } else
    return eventHasSecondFactor && customerKey
      ? `/event/${eventSgId}/search/${strartnumber}/${customerKey}`
      : `/event/${eventSgId}/search/${strartnumber}`;
}

export function stringToUrl(string) {
  return string
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9\-]/g, '');
}

/****************************************************************************************

                                        MEDIA COUNT

*****************************************************************************************/

export function hasMedia(object) {
  if (!object) {
    return false;
  }
  return object.media.mediaInfo.count > 0;
}

export function hasEnoughMedia(mediaCount, fotoFlat, singlePhoto) {
  if (!fotoFlat) {
    return true;
  }
  if (!singlePhoto) {
    return true;
  }

  if (singlePhoto.price.amount !== 0) {
    const minCountNecessary = fotoFlat.price.amount / singlePhoto.price.amount;
    return mediaCount >= minCountNecessary;
  }
}

/****************************************************************************************

                                      Formatted Amount

*****************************************************************************************/

export function getRealAmount(price) {
  return (price.amount / 100).toFixed(2);
}

/****************************************************************************************

                                      Scroll to component

*****************************************************************************************/

export function customScrollToComponent(currentRef, alignValue = 'top', offsetValue) {
  const defaultOffsetValue = window.innerWidth < LAYOUT_BREAKPOINT ? 0 : -90;
  return scrollToComponent(currentRef, { align: alignValue, offset: offsetValue || defaultOffsetValue});
}
