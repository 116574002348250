import React from 'react';
import { FormattedMessageWrappedInSpan } from '../../components/misc';
import { getAppLanguage } from '../../intl/languages';

export default function LegalPage() {
  const linkToContact = (
    <strong>
      <a
        href={`https://helpdesk.sportograf.com/${getAppLanguage()}/support/tickets/new`}
        className="link-blue"
      >
        <FormattedMessageWrappedInSpan
          id="contactPage.support.link"
          defaultMessage="use our contact form"
        />
      </a>
    </strong>
  );

  return (
    <div className="container-960 container-page">
      <h2>
        <FormattedMessageWrappedInSpan id="legalPage.title" defaultMessage="TODO" />
      </h2>
      <p>
        <strong>Sportograf Digital Solutions GmbH</strong>
        <br />
        <strong>Süsterfeldstrasse 170</strong>
        <br />
        <strong>
          52072 Aachen -&nbsp;
          <FormattedMessageWrappedInSpan
            id="legalPage.info.country"
            defaultMessage="Germany"
          />
        </strong>
        <br />
        <strong>
          <FormattedMessageWrappedInSpan
            id="legalPage.info.email"
            defaultMessage="Email"
          />
          : legal@sportograf.com
        </strong>
      </p>
      <p>
        <FormattedMessageWrappedInSpan
          id="contactPage.support.text"
          defaultMessage="To contact our support, please {link}."
          values={{ link: linkToContact }}
        />
      </p>
      <p>
        <FormattedMessageWrappedInSpan
          id="legalPage.paragraphOne.title"
          defaultMessage="TODO"
        />
        :
      </p>
      <p>
        <FormattedMessageWrappedInSpan
          id="legalPage.paragraphOne.lineOne"
          defaultMessage="TODO"
        />
        <br />
        <FormattedMessageWrappedInSpan
          id="legalPage.paragraphOne.lineTwo"
          defaultMessage="TODO"
        />
        <br />
        <FormattedMessageWrappedInSpan
          id="legalPage.paragraphOne.lineThree"
          defaultMessage="TODO"
        />
        <br />
        <FormattedMessageWrappedInSpan
          id="legalPage.paragraphOne.lineFour"
          defaultMessage="TODO"
        />
      </p>
      <p>
        <FormattedMessageWrappedInSpan
          id="legalPage.paragraphTwo"
          defaultMessage="On the basis of Regulation (EU) No 524/2013 on online dispute resolution for consumer disputes, the European Commission expectedly launches a platform for online dispute resolution on 02/15/2016 under the URL http://ec.europa.eu/odr."
        />
      </p>
      <p>
        <FormattedMessageWrappedInSpan
          id="legalPage.paragraphThree"
          defaultMessage="Consumers then have the opportunity to use this platform for the settlement of disputes regarding our online offer."
        />
      </p>
    </div>
  );
}
