/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PresaleEventInfo_eventPresaleInfo$ref: FragmentReference;
declare export opaque type PresaleEventInfo_eventPresaleInfo$fragmentType: PresaleEventInfo_eventPresaleInfo$ref;
export type PresaleEventInfo_eventPresaleInfo = {|
  +presaleMessage: ?string,
  +presale: ?string,
  +$refType: PresaleEventInfo_eventPresaleInfo$ref,
|};
export type PresaleEventInfo_eventPresaleInfo$data = PresaleEventInfo_eventPresaleInfo;
export type PresaleEventInfo_eventPresaleInfo$key = {
  +$data?: PresaleEventInfo_eventPresaleInfo$data,
  +$fragmentRefs: PresaleEventInfo_eventPresaleInfo$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "PresaleEventInfo_eventPresaleInfo",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "presaleMessage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "presale",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a629218ae295c03a7ebb107de1e00dc3';

module.exports = node;
