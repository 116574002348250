import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import CartItem from './CartItem';
import { DvdMsg } from '../../messages';
import AlbumIcon from '@material-ui/icons/Album';

function CartLineItemDvd(props) {
  const { lineItem, eventNames, ...other } = props;

  return (
    <CartItem
      icon={
        <div className="cart-item-icon-container cart-item-icon-container_flex col-xs-5 col-sm-6">
           <AlbumIcon className='cart-item-icon-container_icon-img' />
        </div>
      }
      hasHelpIcon={true}
      lineItem={lineItem}
      title={<DvdMsg />}
      subtitle={
        <FormattedMessageWrappedInSpan
            id="cartProductDvd.subtitle"
            defaultMessage="All of the photos from this order on the DVD"
          />
      }
      eventNames={eventNames}
      {...other}
    />
  );
}

CartLineItemDvd.propTypes = {
  lineItem: PropTypes.object,
  eventNames: PropTypes.object,
};

export default CartLineItemDvd;
